#allMenuMobileNavs a > div
{
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.12);
    border-radius: 8px;
    margin: 10px 0px;
}
#allMenuMobileNavs a:hover > div
{
    background: #eee;
    border-radius: 8px;
}

#gradientBtn
{
    background: linear-gradient(45deg, #FFDC73, #FA7D1E, #D72878, #962DBE, #505AD7);
    background-size: 400% 400%;
    animation: Gradient 5s ease infinite;
    color: #FFFFFF;
    padding: 20px 40px;
    border: none;
    border-radius: 40px;
    font-size: 20px;
    font-weight: 700;
}
#gradientBtn:hover
{
    cursor: pointer;
}

@keyframes Gradient {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

@media only screen and (max-width: 600px) {
    #allMenuMobileNavs
    {
        background: linear-gradient(160deg, #fff, #fff, rgb(241, 241, 241), rgb(241, 241, 241));
    }
}