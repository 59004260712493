.head1
{
    font-weight: 900;
    color: #242F65;
    font-size: 70px;
    line-height: 1em;
    letter-spacing: -1.4px;
    font-family: MerriWeather;
}

.intotext
{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5em;
}

.headImage
{
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 10px;
}

.myBtns
{
    border-radius: 50px;
    font-size: 14px;
    padding: 15px 0px;
    text-transform: capitalize;
}

.introVideo
{
    border-radius: 10px;
    box-shadow: 0px 1px 12px 0px rgba(0,0,0,0.35);
}

.homeAboutImg
{
    width: 90%;
    box-shadow: 50px 51px 12px 0px rgba(0,0,0,0.15);
    border-radius: 5px;
}

.btn
{
    border-radius: 50px;
}

.didYouKnow-img
{
    width: 100%;
}

.galleryImage
{
    height: 250px;
    width: 100%;
    object-fit: cover;
    min-width: 250px;
}

tr th, tr td
{
    padding: 20px;
}

.myModalBg
{
    position: fixed;
    top: 0; left: 0;
    background: rgba(0,0,0,0.65);
    height: 100%; width: 100%;
    display: none;
}

.myModal
{
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
}

.modalImage
{
    width: 100%;
    height: 500px;
    object-fit: contain;
}

.galleryImage:hover
{
    cursor: pointer;
    transform: scale(1.05);
    transition: 0.5s ease;
}

.amenities
{
    font-size: 2.5em;
    font-weight: 500 !important;
    word-spacing: -2px;
    color: rgb(2, 40, 77);
}

.secAmenity
{
    display: flex;
}

.amenityImg
{
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.amenity
{
    /* margin-bottom: -400px; */
    /* border: 1px solid red; */
    height: 400px !important;
    transition: 0.5s ease;
}

.amenity label
{
    background: linear-gradient(rgba(255, 255, 255, 0.247), rgba(44, 44, 44, 0.555));
    width: 100%;
    line-height: 400px;
    position: relative;
    top: -400px;
    text-align: center;
    color: rgb(235, 235, 235);
    font-size: 28px;
    opacity: 0;
    cursor: pointer;
    transition: 0.5s ease;
}

.memCont
{
    background: #222222;
    min-height: 400px;
}

/* .myCard
{
    background-color: rgba(255, 255, 255, 0.726);
    border-radius: 8px;
    min-height: 200px;
    width: 100%;
    backdrop-filter: blur(2px);
} */

@import url('https://fonts.googleapis.com/css2?family=Allerta+Stencil&family=Arapey&display=swap');

#tricolor
{
    background: -webkit-linear-gradient(#FF9933, #fff, #138808);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Allerta Stencil', sans-serif;
}

#triHead
{
    font-size: 26px;
}

@media only screen and (max-width: 900px) {
    #triHead
    {
        font-size: 18px;
    }

    .head1
    {
        text-align: center;
    }
    .intotext
    {
        text-align: center;
        font-size: 14px;
    }
    .headImage
    {
        width: 100%;
        height: 300px;
        object-fit: cover;
        margin: 20px 0px;
    }
    .homeAboutImg
    {
        width: 100%;
        margin-bottom: 30px;
    }
    .head2
    {
        text-align: center;
    }
    .didYouKnow, .didYouKnow-img
    {
        margin: 30px 0px;
    }
    .galleryText
    {
        margin-top: 20px;
    }
    .secAmenity
    {
        display: inline-block;
    }
    .amenity
    {
        width: 100% !important;
        height: 200px !important;
    }
    .amenity label
    {
        line-height: 200px;
        position: relative;
        top: -200px;
        text-align: center;
        color: rgb(235, 235, 235);
        font-size: 28px;
        opacity: 0;
        cursor: pointer;
        transition: 0.5s ease;
        font-size: 16px;
    }
    .amenityImg
    {
        height: 200px;
    }
}