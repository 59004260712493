.serviceImg
{
    height: 300px;
    width: 100%;
    object-fit: cover;
    border-radius: 4px 4px 0px 0px;
}

.serviceIcon
{
    height: 25px;
}

.serviceShortcut
{
    z-index: 100;
    display: flex;
    justify-content: center;
    position: sticky;
    top: 32px; width: 100%;
    background: #fff;
    box-shadow: 0px 30px 20px -10px rgba(0,0,0,0.05);
}

.serviceShortcut::-webkit-scrollbar
{
    display: none;
}

.serviceShortcut a
{
    text-transform: capitalize;
}

.interiorDAmenity
{
    align-items: center;
    height: 280px;
}

.interiorDAmenity p
{
    text-align: center;
}

.interiorDAmenity img
{
    height: 150px;
    width: 150px;
    border-radius: 50%;
}


@media only screen and (max-width: 900px) {
    .justHeading
    {
        font-size: 20px !important;
    }
    .serviceIcon
    {
        margin-bottom: 10px !important;
    }
    .serviceShortcut
    {
        display: flex;
        justify-content: flex-start;
        text-transform: capitalize;
    }
    .serviceShortcut a
    {
        text-transform: capitalize;
        line-height: 5px;
    }
    #subNav
    {
        padding: 15px 5px !important;
        min-width: 70px;
    }
}