.appLogo
{
    height: 60px;
}

.nav__bar
{
    border-bottom: 1px solid rgba(238, 238, 238, 0.388);
    border-top: 1px solid rgba(238, 238, 238, 0.388);
}

.navScroll
{
    display: flex;
    justify-content: center;
}

.navScroll::-webkit-scrollbar
{
    display: none;
}

.textNotFound
{
    font-weight: 300;
    letter-spacing: 5px;
    font-size: 26px;
}

.navLinks i
{
    display: none;
}

.deskMob
{
    display: none;
}

/********************************************************/

.navScroll div button
{
    padding: 10px 8px;
    text-transform: capitalize;
    font-size: 13px;
    border: none;
    background: none;
    border-radius: 4px;
    transition: 0.2s;
    margin: 0px 2px;
}

.navScroll div button:hover, #subNav:hover
{
    background: rgb(217, 242, 255);
}

.navScroll div .active button, .active #subNav
{
    background: rgb(217, 242, 255);
    color: rgb(25, 143, 207);
    font-weight: 600;
}

#topBar div a:hover
{
    color: #5eb2e3 !important;
}

/********************************************************/

@media only screen and (max-width: 900px) {
    .nav__bar
    {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 100;
    }
    .mobMob
    {
        display: none;
    }
    .deskMob
    {
        display: block;
    }
    .navLinks button
    {
        color: #000;
        text-decoration: inherit;
    }
    .navLinks i
    {
        display: block;
        font-size: 18px;
        padding: 10px 0px;
    }
    .nav__links
    {
        padding: 10px;
    }
    .navScroll
    {
        overflow-x: auto;
        justify-content: space-between;
    }
    .navScroll div button
    {
        min-width: 70px;
    }
}