.sitemap_title
{
    padding: 0px;
    margin: 0;
    list-style: none;
}

.sitemap_title li
{
    padding: 3px 0px;
    margin: 0;
    font-size: 13px;
    text-decoration: underline;
}
.sitemap_title li:hover
{
    text-decoration: none;
}

.blogList
{
    padding: 0px;
    margin: 0;
}
.blogList li
{
    padding: 3px 0px;
    margin: 0px 25px;
    font-size: 11px;
    text-decoration: none;
}
.blogList li:hover
{
    text-decoration: underline;
}

#excerptToolTip
{
    border: none;
    border-radius: 7px;
    width: 350px;
    position: absolute;
    font-size: 11px;
    padding: 8px;
    background: #fff;
    color: #000;
    box-shadow: 0px 1px 32px 0px rgba(0,0,0,0.32);
    display: none;
    margin-top: 8px;
}
#excerptToolTip::after
{
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
}

.blogList li:hover + #excerptToolTip
{
    display: block;
}

#excerptHolder p
{
    padding: 0;
    margin: 0;
}

@media only screen and (max-width: 900px) {
    .blogList li:hover + #excerptToolTip
    {
        display: none;
    }
}