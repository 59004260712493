.searchContainer
{
    position: sticky;
    top: 32px;
    z-index: 1000;
}

.searchBar
{
    /* border: 1px solid #eee; */
    padding: 10px;
    border-radius: 40px;
    box-shadow: 0px 1px 12px 0px rgba(0,0,0,0.06);
}

.searchInput
{
    width: 100%;
    padding: 2px 10px;
    border: none;
    outline: none;
}
