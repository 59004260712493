.singleProject
{
    height: auto;
}

.projectAmenity
{
    height: 330px;
    background-color: #fff;
}

.projectAmenity h3
{
    line-height: 50px;
}

.projectAmenity img
{
    height: auto;
    width: 170px;
    border: 1px solid #ddd;
    border-radius: 50%;
    object-fit: cover;
    align-self: center;
}

.bImg
{
    width: 100%;
    height: 600px;
    padding: 8px;
    object-fit: cover;
}

.projectBanner
{
    width: 100%;
    border-radius: 0px;
    box-shadow: 0px 1px 12px 0px rgba(0,0,0,0.14);
}

.projectHeading
{
    font-size: 4rem;
    margin: 20px 0px;
    color: rgb(3, 119, 119);
}

.nav-pills .nav-link.active
{
    background-color: teal;
    box-shadow: none;
}

.nav-pills .nav-link {
    border-radius: 0.25rem;
    font-size: 12px;
    text-transform: uppercase;
    padding: 10px 5px;
    background-color: none;
    font-weight: 500;
    color: rgba(0,0,0,.6);
    margin: 0.5rem;
    border: 1px solid #ddd;
}

.projectFlex
{
    display: flex;
    justify-content: center;
    z-index: 100;
    position: sticky;
    top: 32px; width: 100%;
    background: #fff;
    box-shadow: 0px 30px 20px -10px rgba(0,0,0,0.05);
}

.projectIcon
{
    height: 60px;
}
.navLinksProject
{
    margin: 0px 5px;
}


@media only screen and (max-width: 900px) {
    .projectHeading
    {
        font-size: 2.4rem !important;
    }
    .singleProject
    {
        height: fit-content;
    }
    .projectAmenity
    {
        height: fit-content;
    }
    .bImg
    {
        width: 100%;
        height: 300px;
        padding: 8px 0px;
        object-fit: cover;
    }
    .projectFlex
    {
        display: flex;
        justify-content: start;
        overflow-x: auto !important;
    }
    .navLinksProject
    {
        border: none;
        margin: 0 0;
    }

}