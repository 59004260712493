.gallery {
	display: grid;
	grid-template-columns: auto auto auto auto;
	gap: 0px;
}

.gallery img {
	width: 100%;
    height: 200px;
    object-fit: cover;
}

.gallery img:hover
{
    transform: scale(2);
    transition: 0.2s ease;
    cursor: pointer;
    box-shadow: 0px 1px 32px 0px rgba(177, 177, 177, 0.8);
    border-radius: 8px;
}

@media only screen and (max-width: 900px) {
    .gallery {
        display: grid;
        grid-template-columns: auto auto;
        gap: 0px;
    }
    .gallery img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}