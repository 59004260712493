.partnerImg
{
    width: auto;
    height: 400px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0px 1px 12px 0px rgba(0,0,0,0.12);
}

#prebookImg
{
    width: 100%;
}

@media only screen and (max-width: 900px) {
    .partnerImg
    {
        width: 100%; 
        height: auto;
    }
}