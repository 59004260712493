.myFooter
{
    background-color: #172337;
    padding: 30px 0px;
    color:  rgb(239, 239, 239);
    margin-top: 0px;
    font-size: 12px;
}

.myFooterList
{
    padding: 0;
}

.myFooterList li
{
    font-size: 13px;
    margin: 10px 0px;
    list-style: none;
    padding: 0px;
    /* border: 1px solid red; */
    text-align: start;
    margin: 5px 0px;
}

.myFooterList li a:hover, #secondFooter a:hover
{
    color: #5eb2e3 !important;
}

@media only screen and (max-width: 900px) {
    #mfooter
    {
        padding-bottom: 87px;
    }
    .myFooterList li
    {
        font-size: 15px;
    }
}