svg
{
    display: none;
}

.postUser
{
    height: 35px;
    width: 35px;
    border: 1px solid #eee;
    border-radius: 50%;
    object-fit: contain;
}

.post a
{
  color: #000 !important;
  background: rgb(211, 237, 255);
  padding: 2px 5px;
  border-radius: 4px;
  transition: 0.2s;
  font-weight: 700;
  font-size: 14px;
}
.post a:hover
{
  color: #eee !important;
  background: rgb(32, 32, 32);
}

.postExcerpt
{
    font-size: 13px;
    color: rgba(0,0,0,0.72);
}

.uagb-faq-child__wrapper
{
    box-shadow: 0px 1px 12px 0px rgba(0,0,0,0.12);
    padding: 15px;
    margin: 20px 0px;
    border-radius: 10px;
}

.uagb-question
{
    font-weight: 600;
    line-height: 30px;
}

.wp-block-image figure img, .wp-block-image img
{
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0px 1px 12px 0px rgba(0,0,0,0.22);
    margin: 20px 0px;
}

.wp-block-embed__wrapper
{
    width: 100%;
}


a
{
    text-decoration: none;
    color: inherit;
}

.featuredImage
{
    width: 100% !important;
    object-fit: contain;
    border-radius: 0px;
    /* border-radius: 8px; */
    /* box-shadow: 0px 1px 12px 0px rgba(0,0,0,0.12); */
}

.wp-block-group
{
    padding: 15px;
    border-radius: 10px;
    margin: 20px 0px;
    box-shadow: 0px 1px 12px 0px rgba(0,0,0,0.12);
}

.wp-block-gallery
{
    display: inline-flex;
    overflow-x: auto;
    width: 100%;
}

.wp-block-gallery img
{
    height: 300px;
    width: auto;
    margin: 0px 10px;
}

.has-background
{
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 1px 12px 0px rgba(0,0,0,0.10);
}

.ewen-breadcrumb
{
    position: sticky !important;
    top: 30px;
    background: #fff;
    padding: 20px 15px;
    color: #1a8bcb;
    border-bottom: 1px solid #ddd;
}

.recentPosts
{
    position: sticky !important;
    top: 90px;
}

.recentTitle
{
    font-size: 15px;
    text-align: start;
    font-weight: 600;
}
.recentTitle:hover
{
    font-weight: 700;
}

.recentFeaturedImage
{
    height: 50px; width: 50px;
    object-fit: cover;
    border-radius: 6px;
}

.loader
{
    animation: cssAnimation 0s 8s forwards;
    visibility: visible;
  }
  
  @keyframes cssAnimation {
    to   { visibility: hidden; }
  }

#homeBlogImage:hover, #blogPost:hover, #latestPost:hover
{
    transform: scale(1.03);
    transition: 0.2s;
}

#blogPost
{
    height: fit-content;
}

#blogTitle
{
    font-size: 38px;
}

@media only screen and (max-width: 900px) {
    iframe
    {
        width: 100%;
    }
    .ewen-breadcrumb
    {
        box-shadow: 0px 5px 8px 0px rgba(0,0,0,0.10);
        font-size: 0.85em;
    }
    .recentFeaturedImage
    {
        border-radius: 4px 4px 0px 0px;
        width: 100%;
        height: auto;
    }
    .recentTitle
    {
        font-size: 16px;
        margin: 5px 0px !important;
    }
    #blogPost
    {
        min-height: fit-content;
    }
    #blogTitle
    {
        font-size: 24px;
    }
}