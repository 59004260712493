/* @import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@900&display=swap'); */

.carousel-item img, .carousel-inner
{
    width: 100% !important;
}

.slides
{
    height: fit-content !important;
}

#svg_btn
{
    width: 60%;
}

.carousel-item
{
    padding: 0;
}

.carousel-item img
{
    height: auto !important;
    width: 100% !important;
    object-fit: contain;
}

.headCar
{
    border-radius: 20px 20px 0px 0px;
}

.carousel-inner .active
{
    border-bottom: 0px !important;
}

.carousel-indicators
{
    /* border: 1px solid red; */
    position: absolute;
    bottom: -15px;
}

.carousel-indicators button
{
    height: 8px !important;
    width: 8px !important;
    border-radius: 50%;
}

/* Hero section  */

.hero_section
{
    padding: 80px 0px;
    background-color: #eee;
}

#features li
{
    margin: 0;
    padding: 0;
    font-size: 12px;
}


.heroTitle h1
{
    font-weight: 700 !important;
    color: #000;
    /* font-family: MerriWeather; */
    font-size: 2.8em;
}

/* Project Video  */

.projectVideo iframe
{
    width: 100%;
    height: 600px !important;
    border-radius: 0px !important;
    margin: 0px !important;
    box-shadow: none !important;
}

/* Amenities  */

.amentiesSection
{
    padding: 50px 0px;
}

.amenities
{
    font-size: 2.7em !important;
    line-height: 65px;
}

.amenitiesItems .carousel
{
    box-shadow: 0px 1px 22px 0px rgba(0,0,0,0.25);
    border-radius: 10px;
}

.amenitiesItems .carousel-item
{
    padding: 0 !important;
    height: 400px !important;
}

.amenitiesItems .carousel-item img
{
    border-radius: 10px;
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
}

.amenitiesItems .carousel-caption h5
{
    text-shadow: 2px 1px 2px #000;
}

/* About Us  */
.aboutUs h2
{
    font-weight: 700 !important;
    color: #000;
    /* font-family: MerriWeather; */
    font-size: 2.4em;
}

.aboutUs p
{
    color: rgba(0,0,0,0.8);
}

.homeAboutImg
{
    width: 90%;
    box-shadow: 50px 51px 12px 0px rgba(0,0,0,0.15);
    border-radius: 5px;
}

/* Call To Actions   */

.callToActBtns
{
    text-align: end;
}

.facts .carousel .carousel-indicators
{
    display: none !important;
}

.fact
{
    font-size: 1.3em;
}

.carousel-control-next-icon, .carousel-control-prev-icon
{
    display: none !important;
}

.head2, .head3
{
    font-weight: 900;
    color: #000;
    font-size: 50px;
    /* line-height: 1em; */
    letter-spacing: -1.4px;
    /* font-family: MerriWeather; */
}

#mailFloatingBtn
{
    position: fixed;
    bottom: 30px; right: 30px;
}

#hideonMobile
{
    display: block;
}

#showonMobile
{
    display: none;
}

@media only screen and (max-width: 600px) {
    #mailFloatingBtn
    {
        position: fixed;
        bottom: 90px; right: 20px;
    }
    #hideonMobile
    {
        display: none;
    }
    #showonMobile
    {
        display: block;
    }
    #productCarousel
    {
        display: none;
    }
    #svg_btn
    {
        width: 75%;
    }
    .slides
    {
        height: fit-content !important;
    }
    .carousel-item img
    {
        height: auto !important;
        width: 100% !important;
        object-fit: cover;
    }
    .projectVideo iframe
    {
        height: 300px !important;
    }
    .hero_section
    {
        padding: 50px 0px;
    }
    .amentiesSection
    {
        padding: 40px 0px;
    }
    .amenities
    {
        font-size: 1.6em !important;
        line-height: 40px;
    }
    .goingTR
    {
        flex-direction: column-reverse;
    }
    .aboutUs h2
    {
        text-align: center;
        font-size: 2em !important;
    }
    .aboutImgCont
    {
        text-align: center;
        margin-top: 30px;
    }
    .callToActText h2
    {
        font-size: 2em !important ;
    }
    .callToActBtns
    {
        padding: 20px 0px;
        text-align: center;
    }
    .callToActText, .callToActText p
    {
        text-align: center;
    }
    .fact
    {
        font-size: 1em;
    }
    .head3, .head2
    {
        font-size: 1.8em !important;
        line-height: 40px;
    }
    .didYouKnow,.didYouKnow p
    {
        text-align: center;
    }
}


.container
{
    animation: appear 0.35s;
    position: relative;
}

@keyframes appear
{
    0%{opacity: 0; top: 100px;}
    100%{opacity: 1; top: 0;}
}

iframe{
    margin: 0px !important;
}

#rc-anchor-container
{
    border: none !important;
}