body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:hover
{
  color: initial !important;
}

h1, h2, h3, h4, h5, h6
{
  text-transform: capitalize;
  font-weight: 700 !important;
}

p
{
  text-transform: none;
}

li
{
  padding: 10px 0px;
}

#reasonleft
{
  font-size: 30px;
  color: #000;
  text-align: end;
  padding-right: 30px;
}
#reason_desc_left
{
  font-size: 18px;
  text-align: end;
  line-height: 30px;
  color: rgba(0,0,0,0.75);
  padding-right: 30px;
}
#reasonRight
{
  font-size: 30px;
  color: #000;
  text-align: start;
  padding-left: 30px;
}
#reason_desc_right
{
  font-size: 18px;
  text-align: start;
  line-height: 30px;
  color: rgba(0,0,0,0.75);
  padding-left: 30px;
}
#reasonImg
{
  /* height: 300px; */
  /* width: 100%; */
  object-fit: cover;
  border-radius: 18px;
  box-shadow: 0px 1px 12px 0px rgba(0,0,0,0.15);
}

@media only screen and (max-width: 900px) {
  h2
  {
    font-size: 20px !important;
  }
  #reasonleft, #reasonRight
  {
    font-size: 18px;
    color: #000;
    text-align: start;
    padding: 0px;
  }
  #reason_desc_right, #reason_desc_left
  {
    font-size: 14px;
    text-align: start;
    line-height: 30px;
    color: rgba(0,0,0,0.75);
    padding: 0px;
  }
  #reasonImg
  {
    height: auto;
    width: 100%;
    object-fit: cover;
    border-radius: 18px;
    box-shadow: 0px 1px 12px 0px rgba(0,0,0,0.15);
  }
  #reverse
  {
    flex-direction: column-reverse;
  }
}
