p
{
    text-align: justify;
}

.staff-image
{
    height: auto;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 50%;
}
